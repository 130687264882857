<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <ContactDetails :id="$route.params.id" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContactDetails from '@/components/WAContacts/ContactDetails.vue';
export default {
  name: 'ContactDetailsPage',
  components: {
    ContactDetails,
  },
};
</script>

<style></style>
